//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-344:_8132,_384,_7776,_8952,_980,_1592,_6320,_1816;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-344')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-344', "_8132,_384,_7776,_8952,_980,_1592,_6320,_1816");
        }
      }catch (ex) {
        console.error(ex);
      }