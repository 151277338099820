//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-344:_3364,_8952,_9052,_8624,_5216,_1980,_912,_7132;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-344')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-344', "_3364,_8952,_9052,_8624,_5216,_1980,_912,_7132");
        }
      }catch (ex) {
        console.error(ex);
      }