import shopifyGenericDefaults from '../shopify-generic/config.js';
shopifyGenericDefaults.Widgets.map((w) => {
  if (w.name === 'FilterChips' || w.name === 'Facets' || w.name === 'FacetDialog') {
    if (w.ignoreFields) {
      w.ignoreFields.push('category');
    } else {
      w.ignoreFields = ['category'];
    }
  }
  return w;
});

export default {
  includes: ['shopify-generic'],
  ...shopifyGenericDefaults,
  Widgets: [
    ...shopifyGenericDefaults.Widgets.filter((w) => !['CategoryPageSubcategories'].includes(w.name)),
    {
      name: 'CategoryPageSubcategories',
      type: 'FacetTiles',
      location: '#cm-subcategory-list',
      template: 'facetTiles/subtitles',
      visibleIf: () =>
        shopifyGenericDefaults.Widgets.find((w) => w.name === 'CategoryPageSubcategories').visibleIf() &&
        (window.document.title.replace(' – Brogue Motorcycles', '').endsWith('Parts') ||
          window.location.pathname === '/collections/brogue-motorcycles'),
      facetField: 'category',
    },
  ],
};
